import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import PageTitle from "../../components/page-title"
import Heading from "../../components/heading"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import VideoPlayer from "../../components/video-player"

const Katalog22 = () => {
  const data = useStaticQuery(graphql`
    query {
      poster1: file(
        relativePath: { eq: "videos/poster-katalog-wahnsinnige-dichter.jpg" }
      ) {
        ...largeImage
      }
      poster2: file(
        relativePath: {
          eq: "videos/poster-katalog-psychomotorisch-erkrankte.jpg"
        }
      ) {
        ...largeImage
      }
      poster3: file(
        relativePath: { eq: "videos/poster-katalog-gefallene-genie.jpg" }
      ) {
        ...largeImage
      }
      poster4: file(
        relativePath: { eq: "videos/poster-katalog-selbsttherapierende.jpg" }
      ) {
        ...largeImage
      }
      poster5: file(
        relativePath: { eq: "videos/poster-katalog-vatersuchende.jpg" }
      ) {
        ...largeImage
      }
      poster6: file(
        relativePath: { eq: "videos/poster-katalog-sprachverwirrte.jpg" }
      ) {
        ...largeImage
      }
      poster7: file(
        relativePath: { eq: "videos/poster-katalog-hirnforscher.jpg" }
      ) {
        ...largeImage
      }
      poster8: file(
        relativePath: { eq: "videos/poster-katalog-edle-simulant.jpg" }
      ) {
        ...largeImage
      }
      poster9: file(
        relativePath: { eq: "videos/poster-katalog-vergiftete.jpg" }
      ) {
        ...largeImage
      }
    }
  `)
  return (
    <Layout backdrop="aussen">
      <Seo title="War Hölderlin geisteskrank?" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "War Hölderlin geisteskrank?",
                link: "/k/22",
              },
            ]}
          />
          <PageTitle>War Hölderlin geisteskrank?</PageTitle>
        </Stack>
        <Stack space={6}>
          <Heading as="h2" level={4}>
            Der wahnsinnige Dichter im Turm - Wilhelm Waiblinger
          </Heading>
          <VideoPlayer
            src="katalog/22-wahnsinnige-dichter"
            poster={data.poster1}
          />
        </Stack>
        <Stack space={6}>
          <Heading as="h2" level={4}>
            Der psychomotorisch Erkrankte - Wilhelm Lange
          </Heading>
          <VideoPlayer
            src="katalog/22-psychomotorisch-kranke"
            poster={data.poster2}
          />
        </Stack>
        <Stack space={6}>
          <Heading as="h2" level={4}>
            Das gefallene Genie – Norbert von Hellingrath
          </Heading>
          <VideoPlayer src="katalog/22-gefallene-genie" poster={data.poster3} />
        </Stack>
        <Stack space={6}>
          <Heading as="h2" level={4}>
            Der Selbsttherapierende – Wolfgang Emmerich
          </Heading>
          <VideoPlayer
            src="katalog/22-selbsttherapierende"
            poster={data.poster4}
          />
        </Stack>
        <Stack space={6}>
          <Heading as="h2" level={4}>
            Der Vatersuchende - Jean Laplanche
          </Heading>
          <VideoPlayer src="katalog/22-vatersuchende" poster={data.poster5} />
        </Stack>
        <Stack space={6}>
          <Heading as="h2" level={4}>
            Der Sprachverwirrte – Uwe Henrik Peters
          </Heading>
          <VideoPlayer src="katalog/22-sprachverwirrte" poster={data.poster6} />
        </Stack>
        <Stack space={6}>
          <Heading as="h2" level={4}>
            Der Hirnfoscher – Detlef B. Linke
          </Heading>
          <VideoPlayer src="katalog/22-hirnforscher" poster={data.poster7} />
        </Stack>
        <Stack space={6}>
          <Heading as="h2" level={4}>
            Der edle Simulant – Pierre Bertaux
          </Heading>
          <VideoPlayer src="katalog/22-edle-simulant" poster={data.poster8} />
        </Stack>
        <Stack space={6}>
          <Heading as="h2" level={4}>
            Der Vergiftete – Reinhard Horowski
          </Heading>
          <VideoPlayer src="katalog/22-vergiftete" poster={data.poster9} />
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog22
